import { FunctionalComponent, h } from 'preact'
import { Link } from 'preact-router/match'
import style from './style.css'

export const Header: FunctionalComponent = () => {
  return (
    <header class={style.header}>
      <h1>StJohn3D</h1>
      <nav>
        <Link activeClassName={style.active} href='/'>
          Home
        </Link>
        <Link activeClassName={style.active} href='/profile'>
          Me
        </Link>
        <Link activeClassName={style.active} href='/profile/john'>
          John
        </Link>
      </nav>
    </header>
  )
}

export default Header
